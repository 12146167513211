<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">

     <div class="kt-header__topbar-item">
      <div
        class="kt-header__topbar-wrapper beamerButton"
        id=""
      >
        <span class="kt-header__topbar-icon">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="#000" fill-rule="evenodd"><path d="M17 12h1.5a1.5 1.5 0 010 3h-13a1.5 1.5 0 010-3H7l.558-5.024a4.47 4.47 0 018.884 0L17 12z"/><rect opacity=".3" x="10" y="16" width="4" height="4" rx="2"/></g></svg>
        </span>
      </div>
 
    </div>

    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile">Hola,</span>
          <span class="kt-header__topbar-username kt-hidden-mobile">{{$store.state.passport.user.name}}</span>
          <i class="icon-user"></i>
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <!--
          <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >{{$store.state.passport.user.name[0].toUpperCase()}}</span>
           -->
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser></KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
// import KTSearchDefault from "@/views/theme/topbar/SearchDefault.vue";
// import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/views/theme/topbar/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/views/theme/topbar/DropdownMyCart.vue";
//import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
//import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser2.vue";
import i18nService from "@/common/i18n.service.js";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    // KTSearchDefault,
    // KTDropdownNotification,
    // KTDropdownQuickAction,
    // KTDropdownMyCart,
    //KTDropdownLanguage,
    KTDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>
