<template>
  <div >
    <!--begin: Head -->
    <div
      class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x "
      style="background: linear-gradient(89.71deg, #BF1324 0.23%, #F95812 101.36%);"
    >
        <div class="kt-user-card__avatar align-self-center">
            <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
            <span style="background: white;"
            class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
            ><i class="icon-user"></i></span
            >
        </div>
        <div class="kt-user-card__name align-self-center">{{$store.state.passport.user.name}}</div>
        
        <div class="kt-user-card__badge align-self-end">
            
            <!--
            <span class="btn btn-success btn-sm btn-bold btn-font-md"
            >{{$store.state.passport.user.default_store_name}}</span
            >
            -->
            <v-btn @click="cerrarSesion" text dark id="boton-link-blanco">
                <i class="flaticon-logout mr-2"></i> Cerrar sesión
            </v-btn>
        </div>
      
    </div>
    <!--end: Head -->
    <!--begin: Navigation -->
    <div class="kt-notification">
      
      <div class="kt-notification__custom kt-space-between d-flex justify-space-between">
        <span style="font-family: Roboto;font-weight: 400;color: #8C8C8C;">
            Cambiar tienda
        </span>
        <v-bottom-sheet v-model="sheet" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              color="#EFF8FF"
              v-bind="attrs"
              v-on="on"
              id="boton-tienda-dropdownuser2"
            >
              {{$store.state.passport.user.default_store_name}}
            </v-btn>
          </template>
          <v-list :class="'stores-list'">
            <v-subheader dark><span class="text-h6">Selecciona una tienda:</span></v-subheader>
            <v-list-item>
              <v-combobox @change="refreshUserDefaultStore" clearable v-model="storeSelected" :items="$store.state.passport.user.availables_stores.map(e => ({text: e.name, value: e.id}))">
                <template v-slot:item="{item, active, attrs, on}">
                  <v-list-item v-on="on" v-bind="attrs" #default="{active}" class="text-subtitle-1" style="font-family: Roboto">
                    <v-icon class="mr-2" color="#f95812">mdi-store</v-icon> {{item.text}}
                  </v-list-item>
                </template>
              </v-combobox>
            </v-list-item>
            
            
          </v-list>
        </v-bottom-sheet>
        
      </div>
    </div>
    <!--end: Navigation -->
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "KTDropdownUser",
  data(){
    return {
      sheet: false,
      storeSelected: undefined,
    }
  },
  created(){
    this.storeSelected = {text: this.$store.state.passport.user.default_store_name, value: this.$store.state.passport.user.default_store_id}
  },
  methods: {
    refreshUserDefaultStore(){
      console.log(this.storeSelected.value);
      if(!!this.storeSelected){
        if(this.$store.state.passport.user.availables_stores.find(e => e.id == this.storeSelected.value)){
          var vm = this;
          this.$store
            .dispatch('passport/changeDefaultStore', {id: this.storeSelected.value})
            .then(() => { 
              vm.$store.dispatch('passport/fetchUser');
              vm.sheet = false;
              //vm.$router.go(vm.$router.currentRoute);
              window.location.href = "/";
              // window.location.reload();
            })
            .catch( () => {
              vm.$bvToast.toast("Ocurrio un error", {
                title: `Alerta`,
                variant: 'warning',
                solid: true,
                toaster: 'b-toaster-bottom-center'
              });
              vm.sheet = false;
            });
          
        }
      }
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    cerrarSesion(){
      this.$store.dispatch('passport/signout').then( () => this.$router.push({name: "login", query: {session: 'logout'}}));
    },
    changeUserDefaultStore(store){
      var vm = this;
      this.$store
      .dispatch('passport/changeDefaultStore', store)
      .then(() => { 
        vm.$store.dispatch('passport/fetchUser');
        vm.sheet = false;
        //vm.$router.go(vm.$router.currentRoute);
         window.location.href = "/";
        // window.location.reload();
      })
      .catch( () => {
        vm.$bvToast.toast("Ocurrio un error", {
          title: `Alerta`,
          variant: 'warning',
          solid: true,
          toaster: 'b-toaster-bottom-center'
        });
        vm.sheet = false;
      });
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
<style>
#boton-link-blanco .v-btn__content{
    text-transform: none;
    font-size: 14px !important;
}
#boton-tienda-dropdownuser2 .v-btn__content{
    color: #1988D8;
    text-transform: none;
}
</style>
<style lang="stylus">
  .v-list{
    overflow-y: auto;
  }
  
//   .v-list.stores-list {
//     height: 50vh;
// }
.stores-list{
  padding-top: 0px !important;
}
.stores-list .v-subheader{
  background: linear-gradient(90deg, rgb(191, 19, 36) 0%, rgb(249, 88, 18) 100%) repeat scroll 0% 0% !important;
}
</style>
