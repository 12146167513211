<template>
  <div class="kt-subheader kt-grid__item" id="kt_subheader">
    <div class="kt-container" v-bind:class="{ 'kt-container--fluid': widthFluid }" >
      <div class="kt-subheader__main">
       <v-img  v-if="$store.state.passport.user.default_store_logo && !hide_store_logo" :src="$store.state.passport.user.default_store_logo"  max-width="100" class="mr-2"></v-img>
        
        <h3 class="kt-subheader__title">
          {{ title }} 
        </h3>
        <div class="kt-subheader__breadcrumbs">
          <!--
          <router-link :to="'/'" class="kt-subheader__breadcrumbs-home"
            >&bull;</router-link>
          -->
          <v-breadcrumbs divider=">" :items="items">
            
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                @click="$router.go(-1)"
                :disabled="item.disabled"
              >
                <i v-if="item.text == 'Home'" class="icon-warehouses"></i>
                <div v-else>{{ item.text }}</div>
                
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>
          <!--
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <span
              :key="`${i}-${breadcrumb.id}`"
              class="kt-subheader__breadcrumbs-separator"
            ></span>
            <router-link
              v-if="breadcrumb.route"
              :key="i"
              :to="breadcrumb.route"
              class="kt-subheader__breadcrumbs-link"
            >
              {{ breadcrumb.title }}
            </router-link>
            <span
              class="kt-subheader__desc"
              :key="i"
              v-if="!breadcrumb.route"
              >{{ breadcrumb.title }}</span
            >
          </template>
          -->
        </div>
      </div>
      <div class="kt-subheader__toolbar">
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  data() {
    return {
      show_store_logo: true,
    };
  },
  mounted() {
    this.showStoreLogoImageFn();
  },
  props: {
    breadcrumbs: Array,
    title: String
  },
  data() {
    return {
      hide_store_logo: false
    };
  },
  methods: {
    showStoreLogoImageFn(){
      if (window.location.toString().match(/storegroup/)) {
          this.show_store_logo = false;
      }else{
        if (this.$store.state.passport.user.default_store_logo) {
          this.show_store_logo = true;
        }else{
          this.show_store_logo = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },
    items(){
      if(this.breadcrumbs){
        let arreglo = [];
        const { href } = this.$router.resolve({
          name: 'ordenes',
        });
        arreglo.push({
          text: 'Home',
          disabled: false,
          href: href,
        })

        this.hide_store_logo = false;
        this.breadcrumbs.forEach(e => {
          if(e.hide_store_logo){
            this.hide_store_logo = true;
          }
          arreglo.push({
            text: e.title,
            disabled: false,
            href: e.route,
          });
        });

        return arreglo;
      }else{
        return [];
      }
    }
  },
  watch: {
    $route: {
      handler(to, from){
        this.showStoreLogoImageFn();        
      }
    },
  }
};
</script>